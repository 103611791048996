body, .wrapper .section-container {
  background-color: #f5f7fa;
}

.wrapper .aside-container {
  background-color: #fff;
}

/* ========================================================================
   Component: top-navbar
 ========================================================================== */
.topnavbar {
  background-color: #22335a;
  background-image: linear-gradient(to right, #22335a 0%, #f37742 100%);
  background-repeat: repeat-x;
}
@media (min-width: 992px) {
  .topnavbar .navbar-nav > .nav-item.show > .nav-link, .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
    box-shadow: 0 -3px 0 #1a2644 inset;
  }
}
.topnavbar .navbar-nav > .nav-item > .navbar-text {
  color: #fff;
}
.topnavbar .navbar-nav > .nav-item > .nav-link,
.topnavbar .navbar-nav > .nav-item.show > .nav-link {
  color: #fff;
}
.topnavbar .navbar-nav > .nav-item > .nav-link:hover, .topnavbar .navbar-nav > .nav-item > .nav-link:focus,
.topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
.topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
  color: #060910;
}
.topnavbar .dropdown-item.active, .topnavbar .dropdown-item:active {
  background-color: #22335a;
}

/* ========================================================================
   Component: sidebar
 ========================================================================== */
.sidebar {
  background-color: #fff;
}
.sidebar .nav-heading {
  color: #919DA8;
}

.sidebar-nav > li > a, .sidebar-nav > li > .nav-item {
  color: #515253;
}
.sidebar-nav > li > a:focus, .sidebar-nav > li > a:hover, .sidebar-nav > li > .nav-item:focus, .sidebar-nav > li > .nav-item:hover {
  color: #22335a;
}
.sidebar-nav > li > a > em, .sidebar-nav > li > .nav-item > em {
  color: inherits;
}
.sidebar-nav > li.active, .sidebar-nav > li.active > a, .sidebar-nav > li.active > .nav-item, .sidebar-nav > li.active .sidebar-nav, .sidebar-nav > li.open, .sidebar-nav > li.open > a, .sidebar-nav > li.open > .nav-item, .sidebar-nav > li.open .sidebar-nav {
  background-color: #fcfcfc;
  color: #22335a;
}
.sidebar-nav > li.active > .nav-item > em, .sidebar-nav > li.active > a > em, .sidebar-nav > li.open > .nav-item > em, .sidebar-nav > li.open > a > em {
  color: #22335a;
}
.sidebar-nav > li.active {
  border-left-color: #22335a;
}

.sidebar-subnav {
  background-color: #fff;
}
.sidebar-subnav > .sidebar-subnav-header {
  color: #515253;
}
.sidebar-subnav > li > a, .sidebar-subnav > li > .nav-item {
  color: #515253;
}
.sidebar-subnav > li > a:focus, .sidebar-subnav > li > a:hover, .sidebar-subnav > li > .nav-item:focus, .sidebar-subnav > li > .nav-item:hover {
  color: #22335a;
}
.sidebar-subnav > li.active > a, .sidebar-subnav > li.active > .nav-item {
  color: #22335a;
}
.sidebar-subnav > li.active > a:after, .sidebar-subnav > li.active > .nav-item:after {
  border-color: #22335a;
  background-color: #22335a;
}

/* ========================================================================
   Component: offsidebar
 ========================================================================== */
.offsidebar {
  border-left: 1px solid greyscale(#cccccc);
  background-color: #fff;
  color: #656565;
}