/* ========================================================================
     Component: linksidebar
 ========================================================================== */

//
// A sidebar in offcanvas mode
// Open status toggled via JS
// -----------------------------------------

$linksidebar-bg:           #fff;
$linksidebar-border-color: grayscale(darken($linksidebar-bg, 20%));
$linksidebar-color: $body-color;
$linksidebar-wd: 600px;

.linksidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: $linksidebar-wd;
  margin-top: $navbar-height;
  border-left: 1px solid $linksidebar-border-color;
  background-color: $linksidebar-bg;
  color: $linksidebar-color;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: -1;

  > .list-group {
    min-height: 100%;
    overflow: hidden;
    -webkit-transform: translateZ(0px);
  }

  .progress {
    border: 0;
  }

  .tab-content {
    padding: 0;
    border: 0;
  }
  // change style of tab component inside linksidebar
  .nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, .05);

    > .nav-item > .nav-link {
      background-color: transparent;
      border: 0;
      border-right: 1px solid rgba(0, 0, 0, .05);
      border-radius: 0;
      color: $text-muted;

      &.active {
        color: $primary;
      }
    }
  }


  @include media-breakpoint-up(md) {
    margin-top: $navbar-height;
  }
}

// Global open status (attach to body)
.linksidebar-open {
    .linksidebar {
        overflow-y: auto;
        z-index: $z-index-main-section + 5;
    }
    @include media-breakpoint-up(md) {
        overflow-y: auto;
    }
}

// No tranformation devices
.linksidebar {
    right: -$linksidebar-wd;
}

.no-csstransforms3d {
    .linksidebar-open {
        .linksidebar {
            right: 0;
        }
    }
}


/* Transformation ready devices*/

.csstransforms3d {
    .linksidebar {
        right: 0;
        transform: translate3d( ($linksidebar-wd), 0, 0);
        transition: transform .3s ease, z-index 0s linear .3s;
    }
    .linksidebar-open {
        .linksidebar {
            transform: translate3d(0, 0, 0);
            transition: transform .3s ease;
        }
    }
}
