/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.7.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
// Variables
@import "app/variables";
// Layout
@import "app/layout";
@import "app/layout-extra";
@import "app/layout-animation";
@import "app/top-navbar";
@import "app/sidebar";
@import "app/offsidebar";
@import "app/nodesidebar";
@import "app/linksidebar";
@import "app/user-block";
@import "app/settings";
// Common
@import "app/typo";
@import "app/bootstrap-reset";
@import "app/bootstrap-custom";
@import "app/button-extra";
@import "app/placeholder";
@import "app/cards";
@import "app/circles";
@import "app/dropdown-extra";
@import "app/half-float";
@import "app/animate";
@import "app/slim-scroll";
@import "app/inputs";
@import "app/utils";
@import "app/print";
// Elements
@import "app/spinner";
// Charts
@import "app/radial-bar";
@import "app/chart-flot";
@import "app/chart-easypie";
// Form elements
@import "app/form-select2";
@import "app/form-tags-input";
@import "app/file-upload";
@import "app/summernote";
@import "app/typeahead";
// Tables
@import "app/table-extras";
@import "app/table-angulargrid";
// Maps
@import "app/gmap";
@import "app/vector-map";
// Extras
@import "app/timeline";
@import "app/todo";
@import "app/calendar";
@import "app/mailbox";
@import "app/plans";

.btn-wizard {
}

.btn-wizard-right {
    float: right;
    margin-left: 20px;
}

.progress-wizard {
  position: absolute;
  right: 20px;
}

.img-fluid.circle {
  min-width: 40px;
}

.img-fluid.circle-title {
  min-width: 40px;
  min-height: 40px;
  max-height: 60px;
}

.img-fluid.circle-newcard {
  float:left;
  min-width: 40px;
  min-height: 40px;
  max-height: 40px;
}

.fill-column {
  width: 99%;
}

.wide-column {
  width: 25%;
}

.connector-card {
    cursor: pointer;
}

.htmlarea {
    width: 100%;
}

.clickable {
  cursor: pointer;
}

.titlearea {
  width: 40%;
}

.buttonarea {
  padding-left: 50px;
  width: 60%;

  button {
    margin-right: 20px !important;
    float: right;
  }
}

.w {
  padding: 16px;
  position: absolute;
  z-index: 4;
  border: 1px solid #2e6f9a;
/*  box-shadow: 2px 2px 19px #e0e0e0;
  -o-box-shadow: 2px 2px 19px #e0e0e0;
  -webkit-box-shadow: 2px 2px 19px #e0e0e0;
  -moz-box-shadow: 2px 2px 19px #e0e0e0;
  -moz-border-radius: 8px;
*/
  border-radius: 8px;
  //opacity: 0.8;
  cursor: move;
  background-color: white;
  font-size: 11px;
  -webkit-transition: background-color 0.25s ease-in;
  -moz-transition: background-color 0.25s ease-in;
  transition: background-color 0.25s ease-in;
}

.w:hover {
  background-color: #5c96bc;
  color: white;
}

.aLabel {
  -webkit-transition: background-color 0.25s ease-in;
  -moz-transition: background-color 0.25s ease-in;
  transition: background-color 0.25s ease-in;
}

.aLabel.jtk-hover, .jtk-source-hover, .jtk-target-hover {
  background-color: #1e8151;
  color: white;
}

.aLabel {
  background-color: white;
  opacity: 0.8;
  padding: 0.3em;
  border-radius: 0.5em;
  border: 1px solid #346789;
  cursor: pointer;
  z-index: 20;
  font-size: 11px;
}

.jtk-endpoint {
  z-index: 3;
}

.jtk-drag-hover {
  border: 2px solid orange;
}

path, .jtk-endpoint {
  cursor: pointer;
}

.pagehelp {
  position: absolute;
  right: 80px;
  top: 10px
}
