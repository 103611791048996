/* ========================================================================
   Component: preloader
 ========================================================================== */
/*@noflip*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #22335a;
  background-image: -webkit-linear-gradient(90deg, #22335a 10%, #1b2947 90%); /* Chrome 10+, Saf5.1+ */
  background-image: -moz-linear-gradient(90deg, #22335a 10%, #1b2947 90%); /* FF3.6+ */
  background-image: -ms-linear-gradient(90deg, #22335a 10%, #1b2947 90%); /* IE10 */
  background-image: -o-linear-gradient(90deg, #22335a 10%, #1b2947 90%); /* Opera 11.10+ */
  background-image: linear-gradient(90deg, #22335a 10%, #1b2947 90%); /* W3C */
  z-index: 9999;
  transition: opacity 0.65s;
}

/*@noflip*/
.preloader-progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 225px;
  height: 225px;
  margin: auto;
  overflow: auto;
  background-image: url(../../../assets/img/preloader/crchat_outline.empty.png);
  background-size: 225px 225px;
}

/*@noflip*/
.preloader-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 10px;
  background-image: url(../../../assets/img/preloader/crchat_outline.full.png);
  background-size: 225px 225px;
}

.preloader-hidden {
  display: none;
}

.preloader-hidden-add {
  opacity: 1;
  display: block;
}
.preloader-hidden-add .preloader-progress {
  transition: transform 0.4s ease;
  transform: scale(0);
}

.preloader-hidden-add-active {
  opacity: 0;
}